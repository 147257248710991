/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-12-10",
    versionChannel: "nightly",
    buildDate: "2024-12-10T00:07:10.275Z",
    commitHash: "362c8a846fe41b59836e9ca8c306c45317929a8d",
};
